/*======================================================================
  Selectric
======================================================================*/
.selectricWrapper {
  position: relative;
  margin: 0;
  width: 100%;
  cursor: pointer;
}

.selectricResponsive {
  width: 100%;
}

.selectric {
  border: 1px solid #aeaeae;
  background: #fff;
  position: relative;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

.selectric .label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  margin: 0 30px 0 0;
  padding: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #66696d;
  min-height: 40px;
  text-align: left;
  font-weight: 400;
}
.selectric .button {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 40px;
  background: url('../img/arrows/arrow-botton-gray.png') no-repeat center center;
  text-align: -9999px;
  font-size: 0;
  border: 0px;
}

.selectricOpen {
  z-index: 9999;
}
.selectricOpen .selectric {
  background: #fff;
  -webkit-border-radius: 6px 6px 0 0;
  -moz-border-radius: 6px 6px 0 0;
  border-radius: 6px 6px 0 0;
}
.selectricOpen.selectricAbove .selectric {
  background: #fff;
  -webkit-border-radius: 0 0 6px 6px;
  -moz-border-radius: 0 0 6px 6px;
  border-radius: 0 0 6px 6px;
}
.selectricOpen .selectricItems {
  display: block;
}

.selectricDisabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.selectricHideSelect {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;
}
.selectricHideSelect select {
  position: absolute;
  left: -100%;
  display: none;
}

.selectricInput {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important;
}

.selectricTempShow {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}

/* Items box */
.selectricItems {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  border: 1px solid #aeaeae;
  border-top: none;
  z-index: -1;
}
.selectricItems .selectricScroll {
  height: 100%;
  overflow: auto;
}
.selectricAbove .selectricItems {
  top: auto;
  bottom: 100%;
  border: 1px solid #aeaeae;
  border-bottom: none;
}
.selectricItems ul, .selectricItems li {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 12px;
  line-height: 20px;
  min-height: 20px;
}
.selectricItems li {
  display: block;
  padding: 10px;
  border-top: 1px solid #EEE;
  font-size: 14px;
  line-height: 20px;
  color: #66696d;
  cursor: pointer;
  text-align: left;
}
.selectricItems li:first-child {
  border-top: none;
}
.selectricItems li.selected {
  background: #e4e5e6;
  color: #444;
  border-top: 1px solid #EEE;
}
.selectricItems li:hover {
  background: #e4e5e6;
  color: #444;
}
.selectricItems li.disabled {
  background: #F5F5F5;
  color: #BBB;
  border-top-color: #FAFAFA;
  cursor: default;
}
